import { extendSx } from '@deltasierra/react/theme';
import { Box, Button, Modal as MuiModal, ModalProps as MuiModalProps, Stack, Typography } from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import { ReactNode } from 'react';

export type ModalProps = Pick<MuiModalProps, 'open'> & {
    buttonCancelText?: string;
    buttonDeleteText?: string;
    buttonNextText?: string;
    children: ReactNode | ReactNode[];
    isDisabledCancel?: boolean;
    isDisabledDelete?: boolean;
    isDisabledNext?: boolean;
    onCancel?: () => void;
    onClose?: () => void;
    onDelete?: () => void;
    onNext?: () => void;
    sx?: SxProps<Theme>;
    title: ReactNode | ReactNode[] | string;
};

export const Modal = ({
    buttonCancelText,
    buttonDeleteText,
    buttonNextText,
    children,
    isDisabledCancel,
    isDisabledDelete,
    isDisabledNext,
    onCancel,
    onClose,
    onDelete,
    onNext,
    open,
    sx,
    title,
}: ModalProps): JSX.Element => (
    <MuiModal open={open} onClose={onClose}>
        <Box
            sx={extendSx(
                {
                    backgroundColor: 'white',
                    borderRadius: 3,
                    left: '50%',
                    minWidth: 440,
                    outline: 'none',
                    padding: 3,
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                },
                sx,
            )}
        >
            <Stack spacing={2}>
                <Typography
                    sx={{
                        '> svg': {
                            height: '1em',
                            verticalAlign: 'middle',
                            width: '1em',
                        },
                    }}
                    variant="h2"
                >
                    {title}
                </Typography>
                {children}
                {(onClose || onCancel || onNext || onDelete) && (
                    <Stack direction="row" spacing={2} sx={{ justifyContent: 'flex-end' }}>
                        {(onCancel ?? onClose) && buttonCancelText && (
                            <Button
                                disabled={isDisabledCancel}
                                size="large"
                                variant="outlined"
                                onClick={onCancel ?? onClose}
                            >
                                {buttonCancelText}
                            </Button>
                        )}
                        {onNext && buttonNextText && (
                            <Button disabled={isDisabledNext} size="large" variant="contained" onClick={onNext}>
                                {buttonNextText}
                            </Button>
                        )}
                        {onDelete && (
                            <Button
                                color="error"
                                disabled={isDisabledDelete}
                                size="large"
                                variant="contained"
                                onClick={onDelete}
                            >
                                {buttonDeleteText}
                            </Button>
                        )}
                    </Stack>
                )}
            </Stack>
        </Box>
    </MuiModal>
);

Modal.displayName = 'Modal';
